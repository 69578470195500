'use client';

import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { SecondaryLinkButton } from '@/components/buttons';
import ChevronIcon from '@/components/icons/ChevronIcon';
import { TertiaryHeading } from '@/components/typography';
import { getBackgroundSectionClassName } from '@/utils/marketing-landing-page';

import { ImageWithPlaceholder } from '../../ImageWithPlaceholder';
import { BackgroundColors, ImageProps } from '../types';

import clsx from 'clsx';

const IMG_WIDTH = 600;
const IMG_HEIGHT = 399;

type AlternateImageProps = {
  image: ImageProps;
  title?: string;
  description?: string;
  cta?: {
    label: string;
    url: string;
  };
};

type AlternateImageModuleProps = {
  items: AlternateImageProps[];
  backgroundColor?: BackgroundColors;
  containerClassName?: string;
  isCollapsable?: boolean;
  isReverse?: boolean;
  withShadow?: boolean;
  datatestId?: string;
};

export default function AlternateImageModule({
  items,
  backgroundColor,
  containerClassName,
  isCollapsable,
  isReverse,
  withShadow = true,
  datatestId = 'alt-img-module',
}: AlternateImageModuleProps) {
  const t = useTranslations('general');
  const [isCollapsed, setIsCollapsed] = useState(!!isCollapsable);

  const itemsToShow = isCollapsed ? [items[0]] : items;

  return (
    <section
      className={clsx(getBackgroundSectionClassName(backgroundColor))}
      data-testid={datatestId}
    >
      <ul
        className={clsx(containerClassName, 'flex flex-col gap-5 md:gap-y-20')}
      >
        {itemsToShow.map((item, index) => {
          const isOdd = (index + 1) % 2 !== 0;

          return (
            <li
              key={item.title ?? `alt-image-${index}`}
              className={clsx(
                'flex',
                !isCollapsable && withShadow && 'max-md:rounded max-md:shadow',
                isReverse
                  ? 'flex-col md:flex-row-reverse md:even:flex-row'
                  : 'flex-col md:flex-row md:even:flex-row-reverse'
              )}
            >
              <ImageWithPlaceholder
                className="object-cover w-full rounded h-[200px] sm:h-[300px] md:w-[calc(50%-10px)] md:h-[400px]"
                src={item.image.src}
                width={IMG_WIDTH}
                height={IMG_HEIGHT}
                alt={item.image.alt}
                sizes="(min-width: 1520px) 670px, (min-width: 600px) calc(46.67vw - 30px), (min-width: 500px) calc(100vw - 80px), calc(100vw - 40px)"
              />
              <div className="min-md:hidden w-[20px]"></div>
              <div
                className={clsx(
                  'flex flex-col md:w-[calc(50%-10px)] md:justify-center gap-5 py-5 md:py-0 ',
                  isOdd && isReverse && 'lg:pr-28',
                  isOdd && !isReverse && 'lg:pl-28',
                  !isOdd && isReverse && 'lg:pl-28',
                  !isOdd && !isReverse && 'lg:pr-28',
                  withShadow && 'max-md:px-5'
                )}
              >
                {item.title && <TertiaryHeading>{item.title}</TertiaryHeading>}
                {item.description && (
                  <p dangerouslySetInnerHTML={{ __html: item.description }} />
                )}
                {item.cta && (
                  <SecondaryLinkButton href={item.cta.url}>
                    {item.cta.label}
                  </SecondaryLinkButton>
                )}
                {isCollapsable && (
                  <div
                    className={clsx(
                      'flex font-bold text-teal-40 cursor-pointer',
                      index !== itemsToShow.length - 1 && 'min-h-5 invisible'
                    )}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    {isCollapsed ? t('read-more') : t('read-less')}
                    <ChevronIcon
                      className={clsx(
                        'mt-2 ml-2',
                        !isCollapsed && 'rotate-180',
                        'stroke-teal-5'
                      )}
                    />
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
