'use client';

import { useEffect, useRef, useState } from 'react';

import { CarouselDot } from '@/components/Carousel/CarouselDot';
import { MIN_TOUCH_SCROLL_SIZE } from '@/components/Carousel/constants';

import { ArrowButton } from './ArrowButton';
import SocialReviewCard, { SocialReviewCardProps } from './SocialReviewCard';

import clsx from 'clsx';

type CarouselProps = {
  data: SocialReviewCardProps[];
};

export function Carousel({ data }: CarouselProps) {
  const SIZE_CARD = 320;
  const PADDIND_CARD = 20;

  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [nbCardToFocus, setNbCardToFocus] = useState(3);
  const [leftPosition, setLeftPosition] = useState(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > MIN_TOUCH_SCROLL_SIZE) {
      handleNextButton();
    }

    if (diff < -MIN_TOUCH_SCROLL_SIZE) {
      handlePrevButton();
    }

    setTouchPosition(null);
  };

  const handlePrevButton = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleNextButton = () => {
    if (carouselRef.current !== null && leftPosition < maxScrollWidth.current) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const handleClickDot = (indexDot: number) => {
    setCurrentIndex(indexDot);
  };

  const isDisabled = (direction: string) => {
    if (direction === 'left') {
      return currentIndex <= 0;
    }

    if (direction === 'right' && carouselRef.current !== null) {
      return leftPosition >= maxScrollWidth.current;
    }

    return false;
  };

  useEffect(() => {
    if (carouselRef !== null && carouselRef.current !== null) {
      if (currentIndex === data.length - nbCardToFocus) {
        setLeftPosition(maxScrollWidth.current);
      } else {
        setLeftPosition(currentIndex * (SIZE_CARD + PADDIND_CARD));
      }
    }
  }, [currentIndex]);

  const setScrollWidth = () => {
    if (carouselRef !== null && carouselRef.current !== null) {
      maxScrollWidth.current =
        carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScrollWidth();
      setCurrentIndex(0);

      if (carouselRef.current) {
        setNbCardToFocus(
          Math.round(
            carouselRef.current.offsetWidth / (SIZE_CARD + PADDIND_CARD)
          )
        );
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="relative flex flex-col">
      <ArrowButton
        direction="left"
        isDisabled={isDisabled('left')}
        onClick={handlePrevButton}
      />
      <ArrowButton
        direction="right"
        isDisabled={isDisabled('right')}
        onClick={handleNextButton}
      />
      <div
        ref={carouselRef}
        className="transition-all"
        style={{ transform: `translate(-${leftPosition}px)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <ul className={clsx('flex gap-5')}>
          {data.map((review, index: number) => (
            <li
              className={clsx(
                index > currentIndex + nbCardToFocus - 1 && 'opacity-50'
              )}
              style={{ flex: `0 0 ${SIZE_CARD}px` }}
              key={`card-${review.name}`}
              data-testid={`card-dot-${review.name}`}
            >
              <SocialReviewCard {...review} />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex items-center self-center gap-3 mt-6 sm:hidden">
        {data.map((review, index: number) => (
          <CarouselDot
            id={review.name}
            key={`dot-${review.name}`}
            onClick={() => handleClickDot(index)}
            isActive={index === currentIndex}
          />
        ))}
      </div>
    </div>
  );
}
