'use client';

import { useTranslations } from 'next-intl';

import { BackgroundVideo } from '../modules/shared/BackgroundVideo';
import { PrimaryHeading } from '../typography';
import HomeSearchBar from './HomeSearchBar';
import { SolutionSection } from './SolutionSection';

import clsx, { ClassValue } from 'clsx';

export function Header() {
  const t = useTranslations('home');

  const imageCommonStyle: ClassValue =
    'absolute top-[-48px] sm:top-[-70px] left-0 right-0 w-full !h-[650px]';

  return (
    <header className="flex flex-col justify-end">
      <div
        className={clsx(
          imageCommonStyle,
          'z-[-1] bg-gradient-to-b from-[rgba(0,0,0,0.6)] to-[rgba(0, 0, 0, 0)]'
        )}
      ></div>
      <BackgroundVideo
        className={clsx(imageCommonStyle, 'z-[-2]')}
        src="/videos/home.mp4"
        type="video/mp4"
      />

      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-center gap-10 px-5 mx-auto text-center md:px-10 xl:px-40 h-[calc(650px-48px)] sm:h-[calc(650px-70px)]">
          <PrimaryHeading className="text-white !text-3xl lg:!text-[2.50rem] leading-[3.25rem]">
            {t('h1')}
          </PrimaryHeading>

          <HomeSearchBar />
        </div>
        <SolutionSection className="px-5 py-10 md:px-10" />
      </div>
    </header>
  );
}
