import { IconProps } from './types';

export default function VectorIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={9}
      fill="none"
      {...props}
    >
      <path
        fill="#115D51"
        fillRule="evenodd"
        d="M9.659 6.72a.75.75 0 1 0 1.06 1.06l3.25-3.25L14.5 4l-.53-.53L10.72.22a.75.75 0 0 0-1.061 1.06l1.97 1.97H1.25a.75.75 0 0 0 0 1.5h10.379l-1.97 1.97Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
