import { useTranslations } from 'next-intl';

import AlternateImageModule from '@/components/modules/AlternateImageModule/AlternateImageModule';

import { PRODUCTS } from './constants';

export function ProductSection() {
  const t = useTranslations('home.products');

  return (
    <div className="mb-10 md:mb-20">
      <AlternateImageModule
        datatestId="home-products"
        containerClassName="base-container my-0"
        items={PRODUCTS.map((p) => ({
          image: {
            src: p.imgUrl,
            alt: t(`${p.translationKey}.alt`),
          },
          title: t(`${p.translationKey}.title`),
          description: t(`${p.translationKey}.desc`),
          cta: {
            url: p.ctaLink,
            label: t(`${p.translationKey}.cta-label`),
          },
        }))}
      />
    </div>
  );
}
