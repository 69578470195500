import { getHomeListItemSchema } from './list-items/home-list-item';

import { BreadcrumbList, WithContext } from 'schema-dts';

export function getHomeBreadcrumbSchema(): WithContext<BreadcrumbList> {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [getHomeListItemSchema()],
  };
}
