'use client';

import { useTranslations } from 'next-intl';

import { getHomeBreadcrumbSchema } from '@/lib/breadcrumb-schemas';
import { BackgroundColors } from '@/lib/marketing-page/dto/modules/shared';

import { CompanySection } from '../CompanySection';
import { RootLayout } from '../RootLayout';
import { BreadcrumbListSchema } from '../head/BreadcrumbListSchema';
import SocialReviewCardsModule from '../modules/SocialReviewCardsModule/SocialReviewCardsModule';
import { Header } from './Header';
import { NewsSection } from './NewsSection';
import { ProductSection } from './ProductSection';

export function HomePage() {
  const t = useTranslations('home');

  return (
    <RootLayout noContainer isNavigationTransparent>
      <main>
        <BreadcrumbListSchema schema={getHomeBreadcrumbSchema()} />
        <Header />
        <CompanySection classname="base-container max-md:!mt-0" />
        <ProductSection />
        <SocialReviewCardsModule
          backgroundColor={BackgroundColors.ALMOND}
          title={t('reviews-title')}
        />
        <NewsSection />
      </main>
    </RootLayout>
  );
}
