import { useTranslations } from 'next-intl';

import { getProductPageImage } from '@/models/solutions-pages-utils';

import { Link } from '../Link';
import VectorIcon from '../icons/VectorIcon';
import SecondaryHeading from '../typography/SecondaryHeading';
import { Solution } from './Solution';

import clsx from 'clsx';

type SolutionSectionProps = {
  className?: string;
};

export function SolutionSection({ className }: SolutionSectionProps) {
  const t = useTranslations('home.solutions');

  return (
    <section
      className={clsx(
        'w-full bg-almond-99 flex flex-col items-center gap-5',
        className
      )}
    >
      <SecondaryHeading className="px-5 !text-2xl text-center">
        {t('title')}
      </SecondaryHeading>

      <Link
        href="/solutions"
        className="flex gap-2 font-bold text-center no-underline hover:!underline items-center text-ocean-60"
      >
        {t('link')}
        <VectorIcon />
      </Link>

      <ul className="flex flex-col gap-5 mt-2 md:flex-row md:w-auto max-w-[1360px]">
        <Solution
          target={t('access.target')}
          title={t('access.title')}
          desc={t('access.desc')}
          bulletPoints={[
            t('access.bullet-1'),
            t('access.bullet-2'),
            t('access.bullet-3'),
          ]}
          ctaLbl={t('access.cta')}
          ctaLink="/solutions/access"
          image={getProductPageImage('access')}
        />
        <Solution
          target={t('office.target')}
          title={t('office.title')}
          desc={t('office.desc')}
          bulletPoints={[
            t('office.bullet-1'),
            t('office.bullet-2'),
            t('office.bullet-3'),
          ]}
          ctaLbl={t('office.cta')}
          ctaLink="/solutions/offices"
          image={getProductPageImage('dedicatedOffice')}
        />
        <Solution
          target={t('suite.target')}
          title={t('suite.title')}
          desc={t('suite.desc')}
          bulletPoints={[
            t('suite.bullet-1'),
            t('suite.bullet-2'),
            t('suite.bullet-3'),
          ]}
          ctaLbl={t('suite.cta')}
          ctaLink="/meeting-rooms"
          image={getProductPageImage('meetingRoom')}
        />
      </ul>
    </section>
  );
}
