import { useTranslations } from 'next-intl';
import Image from 'next/image';

import checkmarkIcon from '@/public/icons/checkmarkIcon.svg';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { Link } from '../Link';

type SolutionProps = {
  target: string;
  title: string;
  desc: string;
  bulletPoints: string[];
  image: string;
  ctaLbl: string;
  ctaLink: string;
};

export function Solution({
  target,
  title,
  desc,
  bulletPoints,
  image,
  ctaLbl,
  ctaLink,
}: SolutionProps) {
  const t = useTranslations('general');
  return (
    <li className="lg:w-1/3">
      <div className="flex flex-col px-5 py-5 bg-white gap-y-4">
        <p className="text-sm font-bold uppercase">{target}</p>
        <h3 className="text-lg font-bold xl:text-xl">{title}</h3>
        <p className="flex-grow text-sm">{desc}</p>
        <ul className="flex flex-col gap-1">
          {bulletPoints.map((point) => (
            <li key={point} className="flex items-start">
              <Image
                className="mt-[5px] mr-2"
                src={checkmarkIcon}
                alt={t('checkmark-icon-alt')}
                aria-hidden="true"
              />
              {point}
            </li>
          ))}
        </ul>
        <Link
          href={ctaLink}
          className="font-bold no-underline text-ocean-60 hover:!underline"
        >
          {ctaLbl}
        </Link>
      </div>

      <ImageWithPlaceholder
        className="object-cover w-full"
        src={image}
        width="350"
        height="215"
        alt="Coworking space"
        // sizes="256px"
      />
    </li>
  );
}
