import { useLocale } from 'next-intl';

import { socialReviewsMocks } from '@/mocks/socialReviewsMock';

import { SectionModuleWrapper } from '../shared/SectionModuleWrapper';
import { BackgroundColors } from '../types';
import { Carousel } from './Carousel';

type SocialReviewCardsModuleProps = {
  title?: string;
  backgroundColor?: BackgroundColors;
};

export default function SocialReviewCardsModule({
  title,
  backgroundColor,
}: SocialReviewCardsModuleProps) {
  const locale = useLocale();

  const socialReviewsMock =
    socialReviewsMocks[locale] ?? socialReviewsMocks['en'];

  return (
    <SectionModuleWrapper title={title} backgroundColor={backgroundColor}>
      <Carousel data={socialReviewsMock} />
    </SectionModuleWrapper>
  );
}
