import { useTranslations } from 'next-intl';

import NewsCard, { News } from './NewsCard';

const NEWS: News[] = [
  {
    translationKey: 'article1',
    image: '/public-s3/images/news/bloomberg.webp',
    date: new Date(2022, 4, 9),
    link: 'https://www.bloomberg.com/news/articles/2022-05-09/industrious-expands-in-europe-asia-as-employers-back-flex-work?leadSource=uverify%20wall',
  },
  {
    translationKey: 'article2',
    image: '/public-s3/images/news/the-business-journals.webp',
    date: new Date(2022, 2, 9),
    link: 'https://www.bizjournals.com/bizjournals/news/2022/03/09/critical-moment-flexible-office-coworking.html',
  },
  {
    translationKey: 'article3',
    image: '/public-s3/images/news/bisnow.webp',
    date: new Date(2022, 1, 8),
    link: 'https://www.bisnow.com/dallas-ft-worth/news/office/demand-for-flex-space-on-the-rise-as-companies-contemplate-return-to-office-plans-111797',
  },
];

export function NewsSection() {
  const t = useTranslations('home.news-section');

  return (
    <section className="py-10 lg:py-20">
      <div className="px-5 md:px-10 lg:px-40 m-auto max-w-[1680px]">
        <h3 className="text-sm font-bold text-left lg:text-base">
          {t('title')}
        </h3>
        <ul className="flex gap-2 overflow-x-scroll max-lg:snap-x snap-mandatory max-lg:max-w-[calc(100vw - 20px)] scrollbar-hide lg:ml-[-12px]">
          {NEWS.map((news) => (
            <li
              key={news.translationKey}
              className="max-lg:min-w-[300px] lg:flex-1 snap-always max-lg:snap-start p-3 max-lg:first:pl-0"
            >
              <NewsCard {...news} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
