import CompassLogo from '@/public/images/CompassLogo.svg';
import HyattLogo from '@/public/images/HyattLogo.svg';
import LyftLogo from '@/public/images/LyftLogo.svg';
import PfizerLogo from '@/public/images/PfizerLogo.svg';
import PivotalLogo from '@/public/images/PivotalLogo.svg';
import SalesforceLogo from '@/public/images/SalesforceLogo.svg';

import { ImageWithPlaceholder } from './ImageWithPlaceholder';

import clsx from 'clsx';

type Company = {
  src: string;
  alt: string;
};

const COMPANIES: Company[] = [
  {
    src: PfizerLogo,
    alt: 'Logo Pfizer',
  },
  {
    src: HyattLogo,
    alt: 'Logo Hyatt',
  },
  {
    src: LyftLogo,
    alt: 'Logo Lyft',
  },
  {
    src: PivotalLogo,
    alt: 'Logo Pivotal',
  },
  {
    src: SalesforceLogo,
    alt: 'Logo Salesforce',
  },
  {
    src: CompassLogo,
    alt: 'Logo Compass',
  },
];

type CompanySectionProps = {
  classname?: string;
};

export function CompanySection({ classname }: CompanySectionProps) {
  return (
    <section className={clsx('w-full py-5 lg:py-16', classname)}>
      <ul className="grid grid-cols-3 grid-rows-2 gap-y-10 lg:grid-rows-1 lg:grid-cols-6">
        {COMPANIES.map((company) => (
          <li key={company.alt} className="m-auto">
            <ImageWithPlaceholder
              className="h-[42px] w-[110px] sm:max-w-[9rem] xl:max-w-[10rem]"
              src={company.src}
              alt={company.alt}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}
