import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder';

import clsx from 'clsx';

export type News = {
  translationKey: string;
  image: string;
  date: Date;
  link: string;
};

const IMG_WIDTH = 750;
const IMG_HEIGHT = 500;

export default function NewsCard(news: News) {
  const { translationKey, image, date, link } = news;
  const t = useTranslations('home.news-section');

  const formattedDate = t('date', { date });

  return (
    <Link
      href={link}
      target="_blank"
      className={clsx(
        `flex flex-col h-full rounded border border-grey-90 hover:shadow-[0_0_12px_0_rgba(0,0,0,0.15)]`
      )}
    >
      <ImageWithPlaceholder
        src={image}
        alt={t(`${translationKey}.img-alt`)}
        width={IMG_WIDTH}
        height={IMG_HEIGHT}
        className="object-cover object-center w-full mb-4 rounded"
        sizes="(min-width: 1760px) 426px, (min-width: 960px) calc(30.77vw - 109px), 286px"
      />
      <div className="flex flex-col flex-grow px-5">
        <div className="mb-4 text-sm font-thin">{formattedDate}</div>
        <h4 className="flex-grow mb-5 text-xl font-medium">
          {t(`${translationKey}.title`)}
        </h4>
        <div className="self-end mb-5 font-medium hover:underline text-teal-50">
          {t('read-more')}
        </div>
      </div>
    </Link>
  );
}
