import ChevronIcon from '../../icons/ChevronIcon';

import clsx from 'clsx';

type ArrowButtonProps = {
  isDisabled?: boolean;
  direction: 'left' | 'right';
  onClick: (direction: 'left' | 'right') => void;
};

export function ArrowButton({
  isDisabled,
  direction,
  onClick,
}: ArrowButtonProps) {
  return (
    <button
      className={clsx(
        'flex items-center justify-center absolute rounded-full bg-teal-5 disabled:bg-grey-60 inset-y-0 m-auto z-[1] w-12 h-12 not:hover:shadow max-sm:hidden',
        direction === 'right' ? '-right-5 xl:-right-16' : '-left-5 xl:-left-16'
      )}
      disabled={isDisabled}
      onClick={() => onClick(direction)}
    >
      <ChevronIcon
        className={clsx(
          'stroke-white scale-150',
          direction === 'right' ? '-rotate-90' : 'rotate-90'
        )}
      />
    </button>
  );
}
