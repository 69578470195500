type ProductModel = {
  translationKey: string;
  imgUrl: string;
  ctaLink: string;
};

export const PRODUCTS: ProductModel[] = [
  {
    translationKey: 'product1',
    imgUrl: '/public-s3/images/home/value-1.webp',
    ctaLink: '/solutions',
  },
  {
    translationKey: 'product2',
    imgUrl: '/public-s3/images/home/value-2.webp',
    ctaLink: '/solutions/access',
  },
  {
    translationKey: 'product3',
    imgUrl: '/public-s3/images/home/value-3.webp',
    ctaLink: '/locations',
  },
];
